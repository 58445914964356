<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'base.filter' | translate }}
    v-card-content
      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('pages.settings.capacity_from')"
            v-model="form.capacity_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('pages.settings.capacity_to')"
            v-model="form.capacity_to")
      .cols-x
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.silage.filter.weight_from')"
            v-model="form.weight_from")
        .col-x-2.mb-3
          v-text-field(
            :label="$t('modals.silage.filter.weight_to')"
            v-model="form.weight_to")
      .cols-x
        .col-x-2.mb-3.remove-margin-above-mobile
          v-multiselect(
            item-name="number"
            item-value="id"
            :label="$t('base.silages')"
            :options="silagesList"
            v-model="form.silages_ids")
        .col-x-2
          v-multiselect(
            item-value="id"
            :label="$t('base.housings')"
            :options="housingsList"
            v-model="form.housing_ids")
    v-card-actions
      v-btn.mr-2(
        :loading="loading"
        @click="onApply") {{ 'actions.apply' | translate }}
      v-btn(
        text
        @click="onReset") {{ 'actions.clear' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FilterModal',

  mounted () {
    this.fetchSilagesList()
    this.fetchHousingsList()
    const filters = this.$store.getters.silagesFilters
    for (const key in filters) {
      this.form[key] = filters[key]
    }
  },

  data: () => ({
    form: {
      capacity_from: null,
      capacity_to: null,
      weight_from: null,
      weight_to: null,
      silages_ids: [],
      housing_ids: []
    },
    loading: false
  }),

  computed: {
    ...mapGetters([
      'silagesList',
      'housingsList'
    ])
  },

  methods: {
    ...mapActions([
      'fetchSilagesList',
      'fetchHousingsList',
      'applySilagesFilters'
    ]),

    async onApply () {
      this.loading = true
      const filters = {}
      for (const key in this.form) {
        if (this.form[key] !== null) {
          if (Array.isArray(this.form[key])) {
            if (this.form[key].length) {
              filters[key] = this.form[key]
            }
          } else {
            filters[key] = this.form[key]
          }
        }
      }
      await this.applySilagesFilters(filters)
      this.loading = false
      this.$emit('close')
    },

    onReset () {
      for (const key in this.form) {
        if (Array.isArray(this.form[key])) {
          this.form[key] = []
        } else {
          this.form[key] = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
